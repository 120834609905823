import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tablefilter'
})
export class TablefilterPipe implements PipeTransform {
  transform(value: any[], sortOrder: boolean | boolean = false, sortKey?: string): any {
    if (!value) {
      return value;
    }
    console.log(sortOrder);
    console.log(sortKey);
    value = value.sort((a, b) => {
      if (a[sortKey] > b[sortKey]) {
        if (sortOrder) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (sortOrder) {
          return -1;
        } else {
          return 1;
        }

      }
    });
    return [...value];
  }
}
