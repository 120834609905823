import {Component, OnInit} from '@angular/core';
import {NgbCalendar} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-zapier-old',
  templateUrl: './zapier-old.component.html',
  styleUrls: ['./zapier-old.component.css']
})
export class ZapierOldComponent implements OnInit {
  dateModelFrom;
  dateModelTo;
  week;
  daily_message;
  weekly_message;

  base = window.location.origin;

  // base = 'http://127.0.0.1:80';
  nonce;
  constructor(private calendar: NgbCalendar, private httpClient: HttpClient) {
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
    this.dateModelFrom = this.calendar.getToday();
    this.dateModelTo = this.calendar.getToday();
  }

  generate_daily() {
    this.daily_message = 'waiting...';
    const dateFrom = this.dateModelFrom.year + '-' + this.dateModelFrom.month + '-' + this.dateModelFrom.day;
    const dateTo = this.dateModelTo.year + '-' + this.dateModelTo.month + '-' + this.dateModelTo.day;
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/send-file-range?start_day=' + dateFrom + '&end_day=' + dateTo, {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
      this.daily_message = 'done';
    });
  }

  generate_weekly() {
    this.weekly_message = 'waiting...';
    const week = this.week;
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/bol-search-term-weekly-report?week_number=' + week, {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
      this.weekly_message = 'done';
    });
  }

}
