import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {BolComponent} from "./bol/bol.component";
import {LogincheckGuard} from "./logincheck.guard";
import {ReportComponent} from "./report/report.component";
import {KeywordComponent} from "./keyword/keyword.component";
import {SkuSearchComponent} from "./sku-search/sku-search.component";
import {KeywordAnalysisComponent} from "./keyword-analysis/keyword-analysis.component";
import {ZapierOldComponent} from "./zapier-old/zapier-old.component";
import {CustomKeywordAnalyserComponent} from "./custom-keyword-analyser/custom-keyword-analyser.component";
import {ProductSearchComponent} from "./product-search/product-search.component";

const routes: Routes = [
  {path: 'bol', component: BolComponent, canActivate: [LogincheckGuard], children : [
      {path: 'stock-report', component: ReportComponent},
      {path: 'sku-search', component: SkuSearchComponent},
      {path: 'keyword', component: KeywordComponent},
      {path: 'keyword-analysis', component: KeywordAnalysisComponent},
      {path: 'zapier-old', component: ZapierOldComponent},
      {path: 'custom-keyword-analysis', component: CustomKeywordAnalyserComponent},
      {path: 'product-search', component: ProductSearchComponent},
    ]},
  {path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
