import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginStateService {

  isLoggedIn = false;
  isOtherLoggedIn = false;

  constructor() {
    const il = window.sessionStorage.getItem('isLoggedIn');
    if (!!il) {
      this.isLoggedIn = !!il;
    }
  }

  logout() {
    window.sessionStorage.setItem('token', null);
    this.isLoggedIn = false;
    this.isOtherLoggedIn = false;
    window.sessionStorage.setItem('isLoggedIn', 'false');
  }

  loggedIn(token, userType) {
    window.sessionStorage.setItem('token', token);
    if (userType === 'root') {
      this.isLoggedIn = true;
      this.isOtherLoggedIn = false;
      window.sessionStorage.setItem('isLoggedIn', 'true');
    } else {
      this.isLoggedIn = false;
      this.isOtherLoggedIn = true;
      window.sessionStorage.setItem('isOtherLoggedIn', 'true');
    }
  }

  loggedInOther() {
    this.isOtherLoggedIn = true;
    this.isLoggedIn = false;
    window.sessionStorage.setItem('isLoggedIn', 'false');
    window.sessionStorage.setItem('isOtherLoggedIn', 'true');
  }
}
