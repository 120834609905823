import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ExcelService} from "./excel.service";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {LoginComponent} from "./login/login.component";
import { BolComponent } from './bol/bol.component';
import { ReportComponent } from './report/report.component';
import { KeywordComponent } from './keyword/keyword.component';
import {TablefilterPipe} from "./core/tablefilter.pipe";
import { SkuSearchComponent } from './sku-search/sku-search.component';
import { KeywordFilterPipe } from './core/keyword-filter.pipe';
import { KeywordAnalysisComponent } from './keyword-analysis/keyword-analysis.component';
import { ZapierOldComponent } from './zapier-old/zapier-old.component';
import { CustomKeywordAnalyserComponent } from './custom-keyword-analyser/custom-keyword-analyser.component';
import { ProductSearchComponent } from './product-search/product-search.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BolComponent,
    ReportComponent,
    KeywordComponent,
    TablefilterPipe,
    SkuSearchComponent,
    KeywordFilterPipe,
    KeywordAnalysisComponent,
    ZapierOldComponent,
    CustomKeywordAnalyserComponent,
    ProductSearchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbDatepickerModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
