import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {LoginStateService} from "../login-state.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  base = window.location.origin;
  userName = '';
  password = '';
  error = false;
  nonce;

  constructor(private loginState: LoginStateService
    , private router: Router, private httpClient: HttpClient) {
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
  }

  login() {
    // this.loginState.loggedIn('token', 'root');
    // this.router.navigateByUrl('/bol');
    this.httpClient.post(this.base + '/login', {
      'username': this.userName,
      'password': this.password
    }, {headers: {'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
        if (data['token']) {
          this.loginState.loggedIn(data['token'], data['userType']);
          this.router.navigateByUrl('/bol');
        } else {
          this.error = true;
        }
      }
      , error1 => {
        this.error = true;
      });
  }
}
