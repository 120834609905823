import {Component, OnInit} from '@angular/core';
import {LoginStateService} from "../login-state.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-bol',
  templateUrl: './bol.component.html',
  styleUrls: ['./bol.component.css']
})
export class BolComponent implements OnInit {
  base = window.location.origin;
  nonce;

  constructor(public loginService: LoginStateService, private httpClient: HttpClient, private router: Router) {
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
  }

  logout() {
    this.loginService.logout();
    this.httpClient.post(this.base + '/logout', {}, {headers: {'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
    });
    this.router.navigateByUrl('/');
  }
}
