import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.css']
})
export class KeywordComponent implements OnInit {
  keyword;
  keywords;
  num_items = 5;
  bulk_keywords = '';
  takeVia = 'Search';
  link = '';
  base = window.location.origin;
  nonce;
  keyword_status;

  // base = 'http://0.0.0.0:5000';

  constructor(private httpClient: HttpClient) {
    console.log((window as any).nonce);
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
    this.refreshKeywords();

  }

  refreshKeywords() {
    let token = window.sessionStorage.getItem('token');
    this.httpClient.get(this.base + '/keyword-full', {
      headers: {
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }
    }).subscribe(data => {
      this.keywords = data;
    });
    this.httpClient.get(this.base + '/keyword-status', {
      headers: {
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }
    }).subscribe(data => {
      this.keyword_status = data;
    });
  }

  addKeyword(keyword) {
    let token = window.sessionStorage.getItem('token');
    this.httpClient.get(this.base + '/keyword-create?keyword=' + keyword + '&items=' + this.num_items + '&take_via=' + this.takeVia + '&link=' + this.link, {
      headers: {
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }
    }).subscribe(data => {
      this.refreshKeywords();
    });
  }

  addKeywords(bulk_keywords) {
    let token = window.sessionStorage.getItem('token');
    const tt = bulk_keywords.replaceAll('\n', '@@@@');
    this.httpClient.get(this.base + '/keyword-bulk-create?keywords=' + tt + '&items=' + this.num_items + '&take_via=' + this.takeVia + '&link=' + this.link, {
      headers: {
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }
    }).subscribe(data => {
      this.refreshKeywords();
      this.bulk_keywords = '';
    });
  }


  removeKeyword(key) {
    let token = window.sessionStorage.getItem('token');
    this.httpClient.delete(this.base + '/keyword?keyword=' + key.KEYWORD, {
      headers: {
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }
    }).subscribe(data => {
      this.refreshKeywords();
    });
  }

  updateKeyword(key) {
    let token = window.sessionStorage.getItem('token');
    this.httpClient.put(this.base + '/update-keyword?keyword=' + key.KEYWORD + '&num_items=' + key.NUM_ITEMS + '&take_via=' + key.TAKE_VIA + '&link=' + key.LINK, {}, {
      headers: {
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }
    }).subscribe(data => {
      this.refreshKeywords();
    });
  }

}
