import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'keywordFilter'
})
export class KeywordFilterPipe implements PipeTransform {

  transform(value: any, suffix: string): any {
    if (suffix === 'all') {
      return value;
    } else {
      value = value.filter(val => {
          if (val.indexOf('--') !== -1) {
            return val.split('--')[1].trim() === suffix;
          }
        }
      );
      return value;
    }
  }

}
