import {Component, OnInit} from '@angular/core';
import {NgbCalendar, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ExcelService} from "../excel.service";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  dateModel: NgbDateStruct;
  keywords;
  suffix = 'all';
  selectedKeyword;
  sortType = 'keyword';
  sortReverse = false;
  base = window.location.origin;
  // base = 'http://127.0.0.1:80';
  stocks;
  nonce;

  constructor(private calendar: NgbCalendar, private httpClient: HttpClient, private excelService: ExcelService) {
    this.dateModel = this.calendar.getToday();
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/keyword', {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
      this.keywords = data['data'].map(rec => rec['KEYWORD']);
      this.selectedKeyword = this.keywords[0];
      this.onSearch();
    });
  }

  onSearch() {
    const date = this.dateModel.year + '-' + this.dateModel.month + '-' + this.dateModel.day;
    let suf = '';
    if (this.suffix !== 'all') {
      suf = '--' + this.suffix;
    }
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/report?date=' + date + '&keyword=' + this.selectedKeyword + suf, {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
      this.stocks = data['data'];
    });
  }

  onDownload() {
    this.excelService.exportAsExcelFile(this.stocks, 'stock_of_' + this.selectedKeyword, this.sortReverse,
      this.sortType, ["ID", "COUNTRY", "KEYWORD", "TITLE", "COMPETITOR", "SKU", "URL", "RATING",
        "REVIEWS", "FULFILMENT_TYPE", "STOCK_START_DAY", "STOCK_START", "STOCK_END", "SALES_OF_THE_DAY",
        "SPONSORED_NL", "RANKING_NL", "PRICE", "DISCOUNTED", "SCRAPED_DATE", "LABEL", "SPONSORED_BE",
        "YESTERDAY_STOCK_END_DAY", "SALES_OF_THE_DAY_ADJUSTED", "RANKING_BE"]);
  }

  getLabel(keyword) {
    if (keyword.indexOf('--') !== -1) {
      return keyword.split('--')[1];
    } else {
      return '';
    }
  }
}
