import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent implements OnInit {
  productSheet = false;
  specificationSheet = false;
  reviewSheet = false;
  keywords = '';

  data: any = [];

  base = window.location.origin;

  // base = 'http://0.0.0.0:80';
  nonce;

  constructor(private httpClient: HttpClient) {
    this.refreshJobData();
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
  }

  refreshJobData() {
    setInterval(() => {
      let token = window.sessionStorage.getItem('token');

      this.httpClient.get(this.base + '/get-jobs/app', {
        headers: {
          'token': token,
          'X-CSRF-Nonce': this.nonce
        }
      }).subscribe(data => {
        this.data = data['jobs'];
      });
    }, 5000);
  }

  getSummary(keywords: string) {
    return keywords.length > 100 ? keywords.substring(0, 100) + '...' : keywords;
  }

  downloadFile(id) {
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/download-job' + '?job=' + id, {
      headers: {'token': token, 'X-CSRF-Nonce': this.nonce},
      responseType: 'blob' as 'json' // Set the response type to blob
    }).subscribe(response => {
      const filename = `scr_${id}.xlsx`; // Specify the filename here
      // @ts-ignore: Unreachable code error
      const blob = new Blob([response], {type: 'application/octet-stream'});     // @ts-ignore: Works
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

  createJob() {
    let token = window.sessionStorage.getItem('token');

    this.httpClient.post(this.base + '/create-job', {
      keywords: this.keywords,
      specification: this.specificationSheet ? 1 : 0,
      product: this.productSheet ? 1 : 0,
      review: this.reviewSheet ? 1 : 0
    }, {headers: {'token': token, 'X-CSRF-Nonce': this.nonce}}).subscribe(res => {

    });
    this.keywords = '';
    this.specificationSheet = false;
    this.productSheet = false;
    this.reviewSheet = false;
  }

}
