import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ExcelService} from "../excel.service";

@Component({
  selector: 'app-custom-keyword-analyser',
  templateUrl: './custom-keyword-analyser.component.html',
  styleUrls: ['./custom-keyword-analyser.component.css']
})
export class CustomKeywordAnalyserComponent implements OnInit {
  selectedKeyword = '';
  period = 20;
  keywords = [];
  sortType = 'keyword';
  sortReverse = false;
  base = window.location.origin;
  // base = 'http://127.0.0.1:80';
  aggregate = false;
  analysis;
  nonce;

  constructor(private httpClient: HttpClient, private excelService: ExcelService) {
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
  }

  onSearch() {
    console.log(this.selectedKeyword);
    const tts = this.selectedKeyword.split('\n');
    this.analysis = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < tts.length; i += 1) {
      let t = tts[i].trim();
      if (t === '') {
        continue;
      }
      let token = window.sessionStorage.getItem('token');
      this.httpClient.get(this.base + '/bol-search-term/' + t + '/' + this.period, {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
        if (this.aggregate) {
          let analys = {'TOTAL_BE': 0, 'TOTAL_NL': 0, 'TOTAL': 0, 'KEYWORD': t}
          const analysis_arry = data['periods'];
          for (let i = 0; i < analysis_arry.length; i += 1) {
            let an = analysis_arry[i]
            analys['TOTAL_BE'] += an['TOTAL_BE'];
            analys['TOTAL_NL'] += an['TOTAL_NL'];
            analys['TOTAL'] += an['TOTAL_NL'] + an['TOTAL_BE'];
          }
          this.analysis.push(analys);
          this.analysis = [...this.analysis];

        } else {
          data['periods'].forEach(e => {
            this.analysis.push(e);
          });
          this.analysis = [...this.analysis];
        }
      });
    }
  }

  t() {
    console.log(this.analysis);
  }

  onDownload() {
    if (!this.aggregate) {
      this.excelService.exportAsExcelFile(this.analysis, 'keyword_analysis', this.sortReverse, this.sortType, ["KEYWORD", "TOTAL", "TOTAL_BE", 'TOTAL_NL', 'WEEK', 'YEAR']);
    } else {
      this.excelService.exportAsExcelFile(this.analysis, 'keyword_analysis', this.sortReverse, this.sortType, ["KEYWORD", "TOTAL", "TOTAL_BE", 'TOTAL_NL']);
    }
  }

}
