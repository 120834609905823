import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() {
  }

  public exportAsExcelFile(json: any[], excelFileName: string, sortOrder: boolean, sortKey: string, myHeader: any): void {
    json = json.sort((a, b) => {
      if (a[sortKey] > b[sortKey]) {
        if (sortOrder) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (sortOrder) {
          return 0;
        } else {
          return 1;
        }

      }
    });
    const new_json = [];
    for (let i = 0; i < json.length; i += 1) {
      const newObj = Object.assign({}, json[i]);
      const to_remove = Object.keys(newObj).filter(x => !myHeader.includes(x));
      for (let j = 0; j < to_remove.length; j += 1) {
        delete newObj[to_remove[j]];
      }
      new_json.push(newObj);
    }
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(new_json, {header: myHeader});

    const myworkbook: XLSX.WorkBook = {Sheets: {'data': myworksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(myworkbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }
}
