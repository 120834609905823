import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ExcelService} from "../excel.service";

@Component({
  selector: 'app-keyword-analysis',
  templateUrl: './keyword-analysis.component.html',
  styleUrls: ['./keyword-analysis.component.css']
})
export class KeywordAnalysisComponent implements OnInit {
  selectedKeyword = 'all';
  suffix = 'all'
  period = 20;
  keywords = [];
  sortType = 'keyword';
  sortReverse = false;
  base = window.location.origin;
  // base = 'http://127.0.0.1:80';
  analysis;
  year;
  week;
  specifc_time = false;
  nonce;
  constructor(private httpClient: HttpClient, private excelService: ExcelService) {
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
    const headers = new HttpHeaders();
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/keyword', {
      headers: {
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }}).subscribe(data => {
      this.keywords = data['data'].map(rec => rec['KEYWORD']);
      this.selectedKeyword = this.keywords[0];
    });
  }

  onSearch() {
    if (!this.specifc_time) {
      const headers = new HttpHeaders();
      let token = window.sessionStorage.getItem('token');
      this.httpClient.get(this.base + '/bol-search-term/' + this.selectedKeyword + '/' + this.period, {
      headers: {
        'Accept':'application/octet-stream',
        'token': token,
        'X-CSRF-Nonce': this.nonce
      }}).subscribe(data => {
        this.analysis = data['periods'];
      });
    } else {
      let token = window.sessionStorage.getItem('token');

      this.httpClient.get(this.base + '/bol-search-term-specifc/' + this.selectedKeyword + '/' + this.period + '/' + this.year + '/' + this.week, {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
        this.analysis = data['periods'];
      });
    }
  }

  onDownload() {
    this.excelService.exportAsExcelFile(this.analysis, 'keyword_analysis', this.sortReverse, this.sortType, ["KEYWORD", "CATEGORY", "TOTAL", "TOTAL_BE", 'TOTAL_NL', 'WEEK', 'YEAR']);
  }

}
