import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginStateService} from './login-state.service';

@Injectable({
  providedIn: 'root'
})
export class LogincheckGuard implements CanActivate {

  constructor(private router: Router, private loginState: LoginStateService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {

    if (!this.loginState.isLoggedIn && !this.loginState.isOtherLoggedIn) {
      // alert('You are not allowed to view this page');
      this.router.navigateByUrl('/');
      return false;
    }
    return true;
  }

}
