import {Component, OnInit} from '@angular/core';
import {NgbCalendar, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ExcelService} from "../excel.service";

@Component({
  selector: 'app-sku-search',
  templateUrl: './sku-search.component.html',
  styleUrls: ['./sku-search.component.css']
})
export class SkuSearchComponent implements OnInit {
  keywords;
  sku;
  selectedKeyword;
  sortType = 'keyword';
  sortReverse = false;
  base = window.location.origin;
  // base = 'http://127.0.0.1:80';
  dateModel: NgbDateStruct;
  useDate: boolean = false;
  trimTable: boolean = false;
  stocks;
  nonce;

  constructor(private calendar: NgbCalendar, private httpClient: HttpClient, private excelService: ExcelService) {
    this.dateModel = this.calendar.getToday();
    this.nonce = (window as any).nonce;
  }

  ngOnInit() {
    const headers = new HttpHeaders();
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/keyword', {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
      this.keywords = data['data'].map(rec => rec['KEYWORD']);
      ;
      this.selectedKeyword = this.keywords[0];
      this.onSearch();
    });
  }

  onSearch() {
    let extra_pram = '';
    if (this.useDate) {
      const date = this.dateModel.year + '-' + this.dateModel.month + '-' + this.dateModel.day;
      extra_pram = '&date=' + date;
    }
    let token = window.sessionStorage.getItem('token');

    this.httpClient.get(this.base + '/report/sku?keyword=' + this.selectedKeyword.split('--')[0] + '&sku=' + this.sku + extra_pram, {headers:{'token':token,'X-CSRF-Nonce': this.nonce}}).subscribe(data => {
      this.stocks = data['data'];
    });
  }

  changeUseDate() {
    this.useDate = !this.useDate;
  }

  onDownload() {
    if (!this.trimTable) {
      this.excelService.exportAsExcelFile(this.stocks, 'stock_of_' + this.selectedKeyword, this.sortReverse,
        this.sortType, ["ID", "COUNTRY", "KEYWORD", "TITLE", "COMPETITOR", "SKU", "URL", "RATING",
          "REVIEWS", "FULFILMENT_TYPE", "STOCK_START_DAY", "STOCK_START", "STOCK_END", "SALES_OF_THE_DAY",
          "SPONSORED_NL", "RANKING_NL", "PRICE", "DISCOUNTED", "SCRAPED_DATE", "LABEL", "SPONSORED_BE",
          "YESTERDAY_STOCK_END_DAY", "SALES_OF_THE_DAY_ADJUSTED", "RANKING_BE"]);
    } else {
      this.excelService.exportAsExcelFile(this.stocks, 'stock_of_' + this.selectedKeyword, this.sortReverse,
        this.sortType, ["KEYWORD", "SKU", "SPONSORED_NL", "RANKING_NL", "SPONSORED_BE", "RANKING_BE"]);
    }
  }
}
